<ng-container *ngIf="details() ?? playgroundDetails() as playground">
	<div class="program-container">
		<div *ngIf="playground?.decline_reason!==null&&playground.status==2" class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
			{{"decline_reason_playground"|translate}}
			{{playground?.decline_reason}}
		</div>
		<h3 *ngIf="playground?.decline_reason==null&&playground.status==2" class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
		{{"decline_reason_playground_null"|translate}}
			{{playground?.decline_reason}}
		</h3>
		<div class="grid grid-cols-12 gap-2 bg-semiDarkBlue p-2 rounded-2xl">
			<div
				class="col-span-12 flex items-center justify-center w-full my-8"
			>
				<h3 class="mx-3 text-3xl text-textYellow">
					{{
						translateService.currentLang == "en"
							? playground?.name_en
							: playground?.name_ar
					}}
				</h3>
			</div>
			<!-- image -->
			<div
				*ngIf="!queryData()?.isPending"
				class="program-image-container p-2 md:col-span-3 col-span-12 bg-darkBlue rounded-2xl flex justify-center items-center"
			>
				<img
					[src]="
						playground.media[0]?.url ??
						'/assets/images/image/default/Branch-default.png'
					"
					alt=""
					srcset=""
					class="max-h-[40vh] rounded-lg"
				/>
			</div>

			<!-- Details -->
			<div class="md:col-span-7 md:col-start-5 col-span-12">
				<!-- Highlight -->
				<div class="flex gap-2 md:gap-5 flex-wrap justify-around my-8">
					<div
						*ngIf="
							!!playground?.sport?.name_en ||
							!!playground?.sport?.name_ar
						"
						class="flex flex-col items-center justify-center bg-darkBlue p-2 rounded-2xl"
					>
						<icon [name]="ICONS.sports" iconClass="w-[1.5rem] h-[1.5rem] md:w-[3rem] md:h-[3rem]"></icon>
						<small class="text-textYellow"
							>{{
								"account.academy_programs_schedule.sports"
									| translate
							}}
						</small>
						<p class="text-fontColor text-base break-words text-xs md:text-sm">
							<chip
								colorHex="#69bb16"
								[label]="
									translateService.currentLang == 'en'
										? playground?.sport?.name_en!
										: playground?.sport?.name_ar!
								"
							></chip>
						</p>
					</div>

					<!-- Price -->
					<div
						*ngIf="!!playground.price"
						class="flex flex-col items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
					>
						<icon [name]="ICONS.cost" iconClass="w-[1.5rem] h-[1.5rem] md:w-[3rem] md:h-[3rem]"></icon>
						<small class="text-textYellow"
							>{{
								"account.academy_programs_schedule.price"
									| translate
							}}
						</small>
						<div
							class="flex flex-col justify-center items-center text-fontColor text-base break-words"
						>
							<div class="text-center text-xs md:text-sm">
								<strong class="text-white pr-1">{{
									playground.price
								}}</strong>
								<small>{{
									"SPORT_ACADEMIES.Currency_eg" | translate
								}}</small>
							</div>

							<!-- // Morning Price -->
							<div
								class="text-center text-xs md:text-sm"
								*ngIf="playground.discount"
							>
								<small class="text-lightBlue block"
									>{{ "booking.morningPrice" | translate }}
								</small>
								<small class="text-white block"
									>{{ "booking.morningTime" | translate }}
								</small>
								<strong class="text-white pr-1">{{
									playground.total_amount | number : "2.0-1"
								}}</strong>
								<small>{{
									"SPORT_ACADEMIES.Currency_eg" | translate
								}}</small>
							</div>
						</div>
					</div>

					<!-- Off Days -->
					<div
						*ngIf="playground.days_off.length"
						class="flex flex-col items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
					>
						<icon [name]="ICONS.offDays" iconClass="w-[1.5rem] h-[1.5rem] md:w-[3rem] md:h-[3rem]"></icon>
						<small class="text-textYellow"
							>{{
								"account.academy_programs_schedule.days_off"
									| translate
							}}
						</small>
						<ul class="text-fontColor text-base break-words">
							@for (day of playground.days_off; track $index) {
							<li class="text-white text-xs md:text-sm">
								{{ day | weekDay | translate }}
							</li>
							}
						</ul>
					</div>

					<div
						class="flex flex-col items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
					>
						<icon [name]="ICONS.timer" iconClass="w-[1.5rem] h-[1.5rem] md:w-[3rem] md:h-[3rem]"></icon>
						<small class="text-textYellow"
							>{{ "playground.schedule" | translate }}
						</small>
						<div
							class="flex gap-2 text-fontColor text-base break-words items-center text-xs md:text-sm"
						>
							<!-- From -->
							<div>
								<small class="text-lightBlue block">{{
									"from" | translate
								}}</small>
								<strong class="text-white">{{
									playground.time_from | timeFormat
								}}</strong>
							</div>
							<i
								class="pi text-lightBlue"
								[ngClass]="
									translateService.currentLang == 'en'
										? 'pi-arrow-right'
										: 'pi-arrow-left'
								"
							></i>

							<!-- to  -->
							<div>
								<small class="text-lightBlue block">{{
									"to" | translate
								}}</small>
								<strong class="text-white">{{
									playground.time_to | timeFormat
								}}</strong>
							</div>
						</div>
					</div>
				</div>

				<div class="flex flex-wrap">
					<!-- Description -->
					<div class="w-full">
						<ng-container
							*ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-circle-on',
									label: 'playground.details',
									value:
										translateService.currentLang == 'en'
											? playground.description_en
											: playground.description_ar
								}
							"
						>
						</ng-container>
					</div>

					<!-- number_of_sessions -->
					<!-- <div class="w-1/2">
						<ng-container
							*ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-ticket',
									label: 'account.academy_programs_schedule.no_of_week_training',
									value: playground?.number_of_sessions
								}
							"
						>
						</ng-container>
					</div> -->

					<!-- session_duration -->
					<!-- <div class="w-1/2">
						<ng-container
							*ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-stopwatch',
									label: 'session_duration',
									value:
										program?.duration_session! / 60
										| number : '1.00',
									valueLabel: 'hour'
								}
							"
						>
						</ng-container>
					</div> -->

					<!-- max_number_of_players -->
					<!-- <div class="w-1/2">
						<ng-container
							*ngTemplateOutlet="
											iconItem;
											context: {
												primeIcon: 'pi-hashtag',
												label: 'account.academy_programs_schedule.max_no_of_players',
												value: program?.max_number_of_players,
											}
										"
						>
						</ng-container>
					</div> -->

					<!-- Branch name -->
					<div class="w-1/2">
						<ng-container
							*ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-warehouse',
									label: 'account.account_management.account_info.bank_branch_name',
									value:
										translateService.currentLang == 'en'
											? playground?.branch?.name_en
											: playground?.branch?.name_ar
								}
							"
						>
						</ng-container>
					</div>

					<!-- address -->
					<div class="w-1/2">
						<ng-container
							*ngTemplateOutlet="
								iconItem;
								context: {
									primeIcon: 'pi-map-marker',
									label: 'account.account_management.account_info.address',
									value:
										translateService.currentLang == 'en'
											? playground?.branch?.address_en
											: playground?.branch?.address_ar
								}
							"
						>
						</ng-container>
					</div>

					<ng-container *ngIf="isUserLogin$ | async">
						<!-- Contact Information -->
						<div
							*ngIf="isUserLogin$ | async"
							class="flex items-center justify-center w-full my-8"
						>
							<hr
								class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700"
							/>
							<span class="mx-3 font-medium text-textYellow">{{
								"contactInformation" | translate
							}}</span>
							<hr
								class="w-[20%] h-px bg-gray-200 border-0 dark:bg-gray-700"
							/>
						</div>
						<div class="text-sm text-fontColor break-all my-1 text-center w-3/4 mx-auto">
							{{"contact.customerService"|translate}} <a href="tel:01101003140" class="tel">01101003140</a>
							
						</div>
						<!-- contact name -->
						<!-- <div class="w-1/2">
							<ng-container
								*ngTemplateOutlet="
									iconItem;
									context: {
										primeIcon: 'pi-user',
										label: 'account.account_management.account_info.contact_name',
										value:
											translateService.currentLang == 'en'
												? playground?.branch
														?.contact_name
												: playground?.branch
														?.contact_name
									}
								"
							>
							</ng-container>
						</div>

						<div class="w-1/2">
							<ng-container
								*ngTemplateOutlet="
									iconItem;
									context: {
										primeIcon: 'pi-mobile',
										label: 'account.branches_management.add_branch.mobile_no',
										value: playground?.branch?.mobile_number
									}
								"
							>
							</ng-container>
						</div> -->
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template
	#highlightData
	let-icon="icon"
	let-label="label"
	let-value="value"
	let-valueLabel="valueLabel"
>
	<div
		*ngIf="value"
		class="flex flex-col items-center justify-center bg-darkBlue p-2 px-4 rounded-2xl"
	>
		<icon [name]="icon" iconClass="w-[1.5rem] h-[1.5rem] md:w-[3rem] md:h-[3rem]"></icon>
		<small class="text-textYellow">{{ label | translate }} </small>
		<p class="text-fontColor text-base break-words">
			<strong class="text-white">{{ value }}</strong>
			<small>{{ valueLabel | translate }}</small>
		</p>
	</div>
</ng-template>

<ng-template
	#itemData
	let-label="label"
	let-subLabel="subLabel"
	let-value="value"
	let-valueLabel="valueLabel"
>
	<div
		*ngIf="value"
		class="flex flex-col items-start gap-4 bg-semiDarkBlue p-4 rounded-2xl"
	>
		<div class="flex flex-col w-full justify-between gap-2 break-all">
			<small class="text-textYellow break-all">
				{{ label | translate }}
			</small>
			<small *ngIf="subLabel" class="text-semiDarkGrey break-words">
				{{ subLabel | translate }}
			</small>
			<p class="text-fontColor text-base break-words">
				{{ value }}
				<small>{{ valueLabel | translate }}</small>
			</p>
		</div>
	</div>
</ng-template>

<ng-template
	#iconItem
	let-primeIcon="primeIcon"
	let-label="label"
	let-value="value"
	let-valueLabel="valueLabel"
>
	<span *ngIf="value" class="flex items-center gap-2 mb-2">
		<!-- Icon -->
		<i
			*ngIf="primeIcon"
			class="pi text-textYellow"
			style="font-size: 15px"
			[ngClass]="primeIcon ? primeIcon : ''"
		></i>
		<span>
			<small style="font-size: 12px" class="text-textYellow block"
				>{{ label | translate }}
			</small>
			<p class="text-fontColor text-base break-words">
				<span class="text-white">{{ value }}</span>
				<small>{{ valueLabel | translate }}</small>
			</p>
		</span>
	</span>
</ng-template>
