import { CommonModule } from "@angular/common";
import { Component, inject, input, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IPlaygroundDetails } from "@sportyano/core/models/playgrounds/playground.interface";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { PlaygroundService } from "@sportyano/core/services/playgrounds/playground.service";
import { ChipComponent } from "@sportyano/shared/components/chip/chip.component";
import { IconComponent } from "@sportyano/shared/components/icon/icon.component";
import { ICONS } from "@sportyano/shared/components/icon/models/icon";
import { TimeFormatPipe } from "@sportyano/shared/pipes/time-format/time-format.pipe";
import { WeekDayPipe } from "@sportyano/shared/pipes/week-day/week-day.pipe";
import { Observable } from "rxjs/internal/Observable";

@Component({
	selector: "playground-details",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		IconComponent,
		ChipComponent,
		TimeFormatPipe,
		WeekDayPipe,
	],
	templateUrl: "./playground-details.component.html",
	styleUrl: "./playground-details.component.scss",
})
export class PlaygroundDetailsComponent {
	// Input
	public details = input<IPlaygroundDetails | null>(null);
	// Inject
	public translateService = inject(TranslateService);
	public _router = inject(ActivatedRoute);
	public _authService = inject(AuthService);
	private _playgroundService = inject(PlaygroundService);

	// Observables
	public isUserLogin$: Observable<boolean> =
		this._authService.getAuthenticationState();

	// Signals
	public playgroundDetails = signal<IPlaygroundDetails | null>(null);
	public queryData = signal<{
		isPending: boolean;
		programId: string | null;
	} | null>(null);
	// Public
	public ICONS = ICONS;
}
