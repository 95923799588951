@if (calenderState) {
<div class="w-full rounded-lg p-2 overflow-auto">
	<!-- <div *ngIf="userType === permission.academy || userType === permission.playground" style="color: #cc8925;">
	<i class="pi pi-exclamation-triangle text-primaryRed"></i>
	{{"prevent_reservation_for_playground_and_academy" | translate}}
</div> -->
	<!-- @if (!navigationControllerState) { -->
	<div class="flex justify-around bg-semiLightGray rounded-lg header">
		<div class="p-3">
			<button
				[disabled]="dateNow == startDate || isPreviousWeekExceeds()"
				(click)="navigateToPreviousWeek()"
				*ngIf="dateNow > todayDate"
				class="w-full border-0 font-body capitalize font-bold text-lg flex flex-row align-middle justify-start gap-2"
				[ngClass]="
					isPreviousWeekExceeds()
						? 'text-semiDarkGrey'
						: 'text-textYellow '
				"
			>
				<svg
					class="w-6 h-6 rtl:rotate-180"
					data-slot="icon"
					fill="none"
					stroke-width="1.5"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M15.75 19.5 8.25 12l7.5-7.5"
					></path>
				</svg>
				{{ dateNow | date : "d MMM" }}
			</button>
		</div>
		<div class="p-3 text-center" colspan="5">
			<p class="text-fontColor font-bold text-lg">
				{{ dateNow | date : "MMMM" }}
			</p>
		</div>
		<div class="p-3">
			<button
				[disabled]="isDateExceeds()"
				(click)="navigateToNextWeek()"
				class="w-full border-0 font-bold capitalize text-lg flex flex-row align-middle justify-end gap-2"
				[ngClass]="
					isDateExceeds() ? 'text-semiDarkGrey' : 'text-textYellow '
				"
			>
				{{ week | date : "d MMM" }}
				<svg
					class="w-6 h-6 rtl:rotate-180"
					data-slot="icon"
					fill="none"
					stroke-width="1.5"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="m8.25 4.5 7.5 7.5-7.5 7.5"
					></path>
				</svg>
			</button>
		</div>
	</div>

	<table
		class="w-full"
		[ngClass]="{
			'pointer-events-none':
				(userType === permission.academy ||
					userType === permission.playground) &&
				role === 'user',
			'filter opacity-70':
				(userType === permission.academy ||
					userType === permission.playground) &&
				role === 'user'
		}"
	>
		<thead>
			<tr class="rounded-lg week">
				<th
					class="text-fontColor p-3 text-center font-bold "
				></th>
				@for (day of availableDaysOfWeek(); track $index) {
				<th
					class="text-fontColor p-3 text-center font-bold text-[10px] md:text-sm"
				>
					{{ day | date : "E, dd/MM" }}
				</th>
				}
			</tr>
		</thead>
		<tbody>
			<ng-container
				*ngTemplateOutlet="
					mainCalenderContent;
					context: { items: dayTimes() }
				"
			></ng-container>
		</tbody>
	</table>
</div>
}
<ng-template #mainCalenderContent let-items="items">
	<tr *ngFor="let hour of items; let hourIndex = index">
		<td class="p-4 bg-darkBlue text-center">
			<p class="text-textYellow text-[10px] md:text-sm font-bold">
				{{ hour | timeFormat }}
			</p>
		</td>
		<td
			*ngFor="let day of availableDaysOfWeek(); let dayIndex = index"
			class="relative"
		>
			<ng-container
				*ngIf="
					{ date: day, time: hour }
						| checkSlotAvailability
							: bookedSlots()
							: localBookedSlots()
							: timeFromTo as slotStatus
				"
			>
				<div class="flex justify-center">
					<button
						class="slot-container rounded-2xl flex flex-col justify-center items-center border border-darkBlue md:w-32 h-20"
						[ngClass]="{
						'disabled':
							slotStatus?.state === ScheduleSlotState.disabled ,
						'not-available':
							slotStatus?.state === ScheduleSlotState.booked &&
							slotStatus?.slot?.id,
						selected:
							slotStatus?.state === ScheduleSlotState.selected,
						available:
							slotStatus?.state === ScheduleSlotState.available,
						
					}"
						[disabled]="
							(slotStatus?.state === ScheduleSlotState.booked &&
								slotStatus?.slot?.id) ||
							slotStatus?.state === ScheduleSlotState.disabled
						"
						(click)="
							onDisableAction(
								{ date: day, time: hour },
								slotStatus?.slot!
							)
						"
					>
						<button
							StopClickPropagationDirective
							*ngIf="
								role === 'admin' &&
								slotStatus?.state == ScheduleSlotState.booked &&
								!slotStatus.is_booked
							"
							class="action"
							mat-button
							[matMenuTriggerFor]="menu"
						>
							<i
								class="pi pi-ellipsis-v"
								style="color: white !important"
							></i>
						</button>
						<mat-menu #menu="matMenu">
							@if(slotStatus?.state === ScheduleSlotState.booked
							&& !slotStatus?.slot?.status){
							<button
								mat-menu-item
								(click)="
									onDisableAction(
										{ date: day, time: hour },
										slotStatus?.slot!
									)
								"
							>
								<small class="text-green-400 font-bold">
									<i class="pi pi-refresh text-green-400"></i>
									{{
										"booking.reactiveSlot" | translate
									}}</small
								>
							</button>
							}@else{
							<button
								mat-menu-item
								(click)="
									onDisableAction(
										{ date: day, time: hour },
										slotStatus?.slot!
									)
								"
							>
								<span class="text-textGreen">{{
									"state.select" | translate
								}}</span>
							</button>
							}
						</mat-menu>
						<ng-container
							*ngIf="
								slotStatus?.state !== ScheduleSlotState.disabled
							"
						>
							@if(slotStatus?.state === ScheduleSlotState.booked
							&& slotStatus?.slot?.id){
							<svg
								focusable="false"
								aria-hidden="true"
								class="w-[2rem] h-[2rem]"
							>
								<use
									[attr.xlink:href]="'#icon-un-available'"
								></use>
							</svg>
							<ng-container
								*ngIf="
									slotStatus?.state ==
										ScheduleSlotState.booked &&
									!slotStatus.is_booked &&
									role === 'admin'
								"
							>
								<small style="font-size: 10px">
									<i
										style="font-size: 7px"
										class="pi pi-info-circle"
									></i>
									{{ "booking.bookedByAdmin" | translate }}
								</small>
							</ng-container>
							}@else{
							<svg
								focusable="false"
								aria-hidden="true"
								class="w-[2rem] h-[2rem]"
							>
								<use
									[attr.xlink:href]="'#icon-available'"
								></use>
							</svg>
							}
						</ng-container>
						<span
							[ngStyle]="{ 'font-size': '12px' }"
							class="flex flex-col w-full items-center justify-between px-1 pt-2"
						>
							<small class="block whitespace-nowrap">
								<i
									class="pi pi-calendar"
									style="font-size: 10px"
								></i>

								{{ day }}</small
							>
							<small class="block whitespace-nowrap">
								<i
									class="pi pi-clock"
									style="font-size: 10px"
								></i>

								{{ hour | timeFormat }}</small
							>
						</span>
					</button>
				</div>
			</ng-container>
		</td>
	</tr>
</ng-template>

<svg style="display: none" xmlns="http://www.w3.org/2000/svg">
	<symbol id="icon-available" viewBox="0 0 32 32">
		<path
			fill="#b2ff65"
			style="fill: var(--color1, #b2ff65); stroke: var(--color2, #69bb16)"
			stroke="#69bb16"
			stroke-linejoin="round"
			stroke-linecap="round"
			stroke-miterlimit="10"
			stroke-width="1.96"
			d="M1.628 17.060c0-0.712 0-1.424 0-2.132 0.020-0.048 0.044-0.096 0.052-0.148 0.096-0.616 0.148-1.244 0.284-1.852 1.316-5.904 6-10.256 11.984-11.156 0.328-0.048 0.656-0.1 0.98-0.148 0.692 0 1.384 0 2.076 0 0.048 0.016 0.096 0.048 0.148 0.052 2.408 0.188 4.616 0.952 6.648 2.252 5.236 3.352 7.732 9.88 6.060 15.864-2.072 7.416-9.528 11.908-16.976 10.232-5.856-1.32-10.216-6.020-11.108-11.984-0.048-0.324-0.1-0.652-0.148-0.98z"
		></path>
		<path
			fill="none"
			stroke="#fff"
			style="stroke: var(--color3, #fff)"
			stroke-linejoin="round"
			stroke-linecap="round"
			stroke-miterlimit="133.333"
			stroke-width="2.8"
			d="M10.316 17.068l3.672 3.672 9.172-9.18"
		></path>
	</symbol>

	<symbol id="icon-un-available" viewBox="0 0 32 32">
		<path
			fill="#e74849"
			style="fill: var(--color4, #e74849); stroke: var(--color5, #c62a40)"
			stroke="#c62a40"
			stroke-linejoin="round"
			stroke-linecap="round"
			stroke-miterlimit="10"
			stroke-width="1.96"
			d="M1.628 17.060c0-0.712 0-1.424 0-2.132 0.020-0.048 0.044-0.096 0.052-0.148 0.096-0.616 0.148-1.244 0.284-1.852 1.316-5.904 6-10.256 11.984-11.156 0.328-0.048 0.656-0.1 0.98-0.148 0.692 0 1.384 0 2.076 0 0.048 0.016 0.096 0.048 0.148 0.052 2.408 0.188 4.616 0.952 6.648 2.252 5.236 3.352 7.732 9.88 6.060 15.864-2.072 7.416-9.528 11.908-16.976 10.232-5.856-1.32-10.216-6.020-11.108-11.984-0.048-0.324-0.1-0.652-0.148-0.98z"
		></path>
		<path
			fill="#fff"
			style="fill: var(--color3, #fff); stroke: var(--color3, #fff)"
			stroke="#fff"
			stroke-linejoin="round"
			stroke-linecap="round"
			stroke-miterlimit="10"
			stroke-width="0.8"
			d="M25.556 17.26h-18.74c-0.528 0-0.956-0.428-0.956-0.956v-1.1c0-0.528 0.428-0.956 0.956-0.956h18.74c0.528 0 0.956 0.428 0.956 0.956v1.1c0.004 0.528-0.428 0.956-0.956 0.956z"
		></path>
	</symbol>
</svg>
